<template>
  <!-- 登录/注册页面 -->
  <div class="container" id="login" style="margin-top: 4%">
    <homeView class="position-fixed" style="filter: blur(35px); z-index: -1" />

    <div class="d-flex justify-content-center w-100 position-relative">
      <div class="d-none d-xl-flex col-3 justify-content-center" style="background-color: #0e1421">
        <img
          src="@/assets/img/main/login_pic.jpg"
          style="width: 100%; height: 100%; max-height: 750px; "
          class="m-auto"
        />
      </div>

      <div
        class="text-center p-4 login_reg_div"
        style="max-width: 350px; background-color: rgba(255, 255, 255, 0.8)"
      >
        <div class="change_lo_re fw-bolder mt-3">
          <span
            :class="Id != 'R' ? 'thishover' : ''"
            @click="this.$router.push('/Login')"
            >{{ $t("login1") }}</span
          >
          <span
            :class="Id == 'R' ? 'thishover' : ''"
            @click="this.$router.push('/Login/R')"
            >{{ $t("Sign_Up") }}</span
          >
        </div>

        <div v-if="Id === 'R'">
          <Register></Register>
        </div>
        <div v-else>
          <LoginPart></LoginPart>
        </div>

        <div class="">
          <div class="mb-2">
            <span class="fs-tenfive fw-bolder">{{ $t("login_text1") }}</span>
          </div>
          <div class="d-flex flex-row justify-content-center">
            <a class="" href="/gowin.mobileconfig" target="_blank" style="margin-right: 8px">
              <img
                class=""
                src="@/assets/img/main/Yfc020c87j0.png"
                style="height: 40px"
            /></a>
            <a class="" href="/gowin.apk" target="_blank">
              <img
                class=""
                src="@/assets/img/main/c5Rp7Ym-Klz.png"
                style="height: 40px"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Register from "./Register.vue";
import LoginPart from "./Home/LoginPart.vue";
import homeView from "./Home.vue";

export default {
  name: "login",
  setup() {},
  data() {
    return {};
  },
  computed: {
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  components: { LoginPart, Register, homeView },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
